import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import {
  AuthPage,
  PhoneCheckSimple,
  RegisterPage,
  LoginTypes,
} from '@features/user/ui';
import { AuthBlock } from '@features/user/ui/block';
import {
  AuthTemplate,
  Notifications,
  useCheckIdentifyAuthType,
  usePurchaseFromQuery,
} from '@ui';
import { useCallback, useState } from 'react';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';
import {
  PhoneFormValues,
  useCheckPhoneFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const AuthNewPage = () => {
  const [login, setLogin] = useState<string | null>(null);
  const [authType, setAuthType] = useState<AuthTypes | null>(null);
  const [loginType, setLoginType] = useState<LoginTypes | null>(null);
  const [isChecked, setCheckingStatus] = useState<boolean>(false);
  const { askHcaptchaToken, component: captchaComponent } =
    useHCaptcha(SITE_KEY);
  const [token, setToken] = useState<string>('');

  const { CheckPhoneFormSchema } = useCheckPhoneFormSchema();
  const methods = useForm<PhoneFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(CheckPhoneFormSchema),
    mode: 'onChange',
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const { handleCheckIdentify, checkIdentify } = useCheckIdentifyAuthType({
    setAuthType,
    setLoginType,
    setCheckingStatus,
    beforeCheckingHandler: setLogin,
    getCaptcha: askHcaptchaToken,
    setToken,
  });

  const { isPurchase } = usePurchaseFromQuery({
    onPurchase: checkIdentify,
  });

  const onBackHandler = () => {
    setLoginType(null);
    setCheckingStatus(false);
    setAuthType(null);
  };

  const getAuthComponent = useCallback(() => {
    if (!isChecked || !login || !authType) {
      return null;
    }

    switch (authType) {
      case 'auth':
        if (!loginType) {
          return null;
        }

        return (
          <AuthPage
            type={loginType}
            phone={login}
            onBackHandler={onBackHandler}
            token={token}
          />
        );
      case 'register':
        return (
          <RegisterPage
            phone={login}
            onBackHandler={!isPurchase ? onBackHandler : undefined}
            token={token}
          />
        );
    }
  }, [isChecked, login, authType, loginType, token, isPurchase]);

  const onSubmit = (type: LoginTypes) =>
    handleSubmit((values) => handleCheckIdentify(values, type));

  return (
    <AuthTemplate>
      <AuthBlock>
        <>
          {captchaComponent}
          {!isChecked || !authType ? (
            <>
              <h1 className="h1">Вход/Регистрация</h1>
              <PhoneCheckSimple
                phone={login}
                onSubmit={onSubmit}
                isValid={isValid}
                isSubmitting={isSubmitting}
                setValue={setValue}
                methods={methods}
              />
            </>
          ) : (
            getAuthComponent()
          )}
          <Notifications target="authorization" />
        </>
      </AuthBlock>
    </AuthTemplate>
  );
};
